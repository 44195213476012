import React, { useRef, useState } from "react";
import FollowUpsDetails from "../FollowUpsDetails";
import {
  downloadFile,
  generatePreSignedUrl,
  getFilenameFromUrl,
  getUserType,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import { icons, paymentAbstractPath } from "utils/constants";
import FileUpload from "components/form/FileUpload";
import TextArea from "components/form/TextArea";
import Button from "components/form/Button";
import moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import { isEqual } from "lodash";
import { useDispatch } from "react-redux";
import { sendRemarks } from "store/slices/accountsSlice";
import { throwError, throwSuccess } from "store/slices";
import { useNavigate, useParams } from "react-router-dom";
import AbstractRegistrationPass from "../AbstractRegistrationPass";
import "./UserRegistrationState.scss";

const UserRegistrationState = ({ data, paperData, handleSuccess }) => {
  const searchParams = useParams();
  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFollowUps, setIsFollowUps] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { status } = data;
  const {
    submittedPapersStatus = {},
    follow_ups_stage = [],
    payment_status,
    payment_created_at,
    id,
    event_id,
    abstract_payment_chatting: revisionRemarks,
    is_self_paid,
  } = paperData || {};
  const { acceptance_letter } = submittedPapersStatus || {};
  const isActiveState = status >= 4;
  const acceptanceStateDone = acceptance_letter && status >= 4;
  const isShowFollowSteps = follow_ups_stage?.some((o) => o?.send_at);
  const paymentUnderProcess = ["0"].includes(payment_status);
  const paymentNotAccepted = ["2"].includes(payment_status);
  const paymentIsAccepted = ["1"].includes(payment_status);
  const isPaymentDoneItself = is_self_paid === "1";

  const memberType = getUserType();

  const initialValues = {
    id: id,
    remarks: "",
    replay_to: "",
    payment_proof: "",
  };

  const validationSchema = Yup.object().shape({
    remarks: Yup.string().required("Remarks are required."),
    payment_proof: Yup.string().required("Payment Proof is required."),
  });

  const handleSave = async (value) => {
    setIsLoading(true);
    const response = await dispatch(sendRemarks(objectToFormData(value)));
    if (response?.status === 200) {
      if (formRef.current) {
        formRef.current.resetForm();
      }
      dispatch(throwSuccess(response?.message));
      handleSuccess();
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setIsLoading(false);
  };

  const getRemarkLabel = (remark) => {
    if (remark?.user_type === "Resource") {
      return `${remark?.user_type} Reason ${remark?.user_counter} :`;
    }
    if (remark?.user_type === "Client") {
      return `Your reply ${remark?.user_counter} :`;
    }
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div
          className={`steps-block ${
            isActiveState && acceptanceStateDone ? "active" : "not-active"
          }`}
        >
          {paymentIsAccepted ? (
            <img src={icons?.RightIcon} alt="right" />
          ) : (
            " 5"
          )}
        </div>
        <div>
          <div
            className={`text-16-500-20 ${
              isActiveState && acceptanceStateDone
                ? "color-new-car"
                : "color-6866"
            }`}
          >
            Registration{" "}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="sparator-block">
          <span className="hr-line" />
        </div>

        {acceptanceStateDone ? (
          <div className="w-100 cmt-15">
            {payment_status ? (
              <>
                {paymentIsAccepted && (
                  <>
                    {isPaymentDoneItself ? (
                      <>
                        <div className="text-14-500-18 color-6866 cmb-12">
                          Payment verification has been completed !!
                        </div>
                        <div className="text-14-500-18 color-6866 cmb-12">
                          You have paid successfully! The invoice will be sent
                          to your registered email.
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="text-14-500-18 color-6866 cmb-12">
                          You have paid successfully !!{" "}
                        </div>
                        <div className="text-14-500-18 color-6866 cmb-12">
                          Payment verification has been completed !!
                        </div>
                      </>
                    )}
                    <AbstractRegistrationPass paperData={paperData} />
                  </>
                )}
                {paymentUnderProcess && (
                  <>
                    <div className="text-14-500-18 color-6866 cmb-12">
                      Your payment is under verification. Our team will update
                      the status shortly.
                    </div>

                    {payment_created_at && (
                      <div className="d-flex gap-2 text-14-300-18 color-black-olive cmb-12">
                        <img src={icons.calendarCheck} alt="calendar" />
                        {moment(payment_created_at).format("DD-MMM-YYYY")}
                      </div>
                    )}

                    {paymentUnderProcess && (
                      <div className="text-14-500-18 bg-f6de info-border-payment cps-12 cpt-8 cpe-12 cpb-8 w-fit">
                        Verification Under Progress
                      </div>
                    )}
                  </>
                )}
                {paymentNotAccepted && (
                  <>
                    <div className="text-14-500-18 color-6866 cmb-12">
                      Your Payment has not verified successfully!
                    </div>

                    {payment_created_at && (
                      <div className="d-flex gap-2 text-14-300-18 color-black-olive cmb-12">
                        <img src={icons.calendarCheck} alt="calendar" />
                        {moment(payment_created_at).format("DD-MMM-YYYY")}
                      </div>
                    )}
                    {revisionRemarks?.map((elem, index) => {
                      const isForm = elem?.is_visible_reupload === "1";
                      return (
                        <div className="cmb-20" key={index}>
                          <div className="text-14-500-18 color-6866 cmb-12">
                            {getRemarkLabel(elem)}

                            {titleCaseString(elem?.remarks)}
                          </div>
                          {isForm && (
                            <>
                              <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSave}
                                innerRef={formRef}
                              >
                                {(props) => {
                                  const {
                                    values,
                                    errors,
                                    handleChange,
                                    handleSubmit,
                                    setFieldValue,
                                    resetForm,
                                  } = props;
                                  const { remarks } = values;
                                  return (
                                    <form>
                                      <div className="col-md-12 col-10 w-50">
                                        <FileUpload
                                          fileType="all"
                                          id="payment_proof"
                                          label="Re-Upload Payment Proof *"
                                          onChange={(e) => {
                                            const fileName = e.target.fileName;
                                            setFieldValue("fileName", fileName);
                                            handleChange(e);
                                          }}
                                          fileText={getFilenameFromUrl(
                                            values?.fileName || "File"
                                          )}
                                          error={errors?.payment_proof}
                                        />
                                      </div>
                                      <div className="w-50 text-14-400-17 color-6866 cmb-18 cmt-16">
                                        <TextArea
                                          placeholder={"Give Your Comments"}
                                          id="remarks"
                                          value={remarks}
                                          error={errors?.remarks}
                                          onChange={(e) => {
                                            handleChange(e);
                                            setFieldValue(
                                              "replay_to",
                                              elem?.id
                                            );
                                          }}
                                        />
                                      </div>
                                      <div className="d-flex align-items-center gap-3">
                                        <div>
                                          <Button
                                            text="Submit"
                                            className="h-35 cps-20 cpe-20"
                                            btnStyle="primary-dark"
                                            disabled={isEqual(
                                              initialValues,
                                              values
                                            )}
                                            btnLoading={isLoading}
                                            onClick={handleSubmit}
                                          />
                                        </div>
                                        <div>
                                          <Button
                                            text="Back"
                                            className="h-35 cps-20 cpe-20"
                                            btnStyle="primary-outline"
                                            onClick={resetForm}
                                          />
                                        </div>
                                      </div>
                                    </form>
                                  );
                                }}
                              </Formik>
                            </>
                          )}
                          {elem?.payment_proof && (
                            <div className="d-flex gap-3 cmt-10">
                              <div
                                className="cmb-16 text-16-500-20 color-7cff pointer border-end cpe-20"
                                onClick={async () => {
                                  if (elem?.payment_proof) {
                                    let downloadContent =
                                      await generatePreSignedUrl(
                                        elem?.payment_proof,
                                        paymentAbstractPath
                                      );

                                    dispatch(downloadFile(downloadContent));
                                  }
                                }}
                              >
                                View
                              </div>
                              <div
                                className="cmb-16 text-16-500-20 color-7cff pointer"
                                onClick={async () => {
                                  if (elem?.payment_proof) {
                                    dispatch(downloadFile(elem?.payment_proof));
                                  }
                                }}
                              >
                                Download
                              </div>
                            </div>
                          )}
                          {revisionRemarks?.length - 1 === index &&
                            elem?.user_type === "Client" &&
                            elem?.payment_proof &&
                            paymentNotAccepted && (
                              <>
                                <div className="text-14-500-18 bg-f6de info-border-payment cps-12 cpt-8 cpe-12 cpb-8 w-fit ">
                                  Verification Under Progress
                                </div>
                              </>
                            )}
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            ) : isShowFollowSteps ? (
              <div className="followup-main-block">
                <div className="follow-ups-block border br-5 w-100 cmb-8">
                  <div
                    className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                    onClick={() => {
                      setIsFollowUps(!isFollowUps);
                    }}
                  >
                    <span>Follow Ups</span>
                    <i
                      className={`${
                        isFollowUps ? "bi bi-chevron-up" : "bi bi-chevron-down"
                      }`}
                      style={{ fontSize: "15px" }}
                    />
                  </div>

                  {isFollowUps && (
                    <FollowUpsDetails
                      steps={follow_ups_stage}
                      paperData={paperData}
                    />
                  )}
                </div>

                
              </div>
            ) : (
              <>
                <div className="text-14-500-15 color-6866 cmb-14">
                  Hurry pay now to book your slot !!
                </div>
                <div className="d-flex">
                  <Button
                    text="Pay now"
                    btnStyle="primary-dark"
                    isRounded
                    className="cps-15 cpe-15 h-35"
                    onClick={() => {
                      localStorage.isRedirectToRegister = 1;
                        localStorage.prevRoute = window.location.pathname;
                      navigate(
                        `/${memberType}/conferences-and-events/event-details/${event_id}/conference-details?paper_id=${searchParams.paperId}`
                      );
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="w-100 cpt-20 cpb-20"></div>
        )}
      </div>
    </>
  );
};

export default UserRegistrationState;
