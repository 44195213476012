import Card from "components/Layout/Card";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSubmittedAbstractsDetails } from "store/slices";
import { icons } from "utils/constants";
import UserAbstractState from "./UserAbstractState";
import UserPlagiarismState from "./UserPlagiarismState";
import UserReviewState from "./UserReviewState";
import UserAcceptanceState from "./UserAcceptanceState";
import UserRegistrationState from "./UserRegistrationState";
import UserAttendanceState from "./UserAttendanceState";
import "./NewViewPaper.scss";

const NewViewPaper = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
const revisionFormRef=useRef()
  const abstractRevisionFormRef = useRef(null);
  const { paperType, paperId } = params;
  const [isAddRevisionAbstract, setIsAddRevisionAbstract] = useState(false);
  const [paperData, setPaperData] = useState({});
  const [scores, setScores] = useState([
    { id: "originality", label: "Originality", score: 0 },
    {
      id: "contribution_to_the_field",
      label: "Contribution to the Field",
      score: 0,
    },
    { id: "technical_quality", label: "Technical Quality", score: 0 },
    {
      id: "clarity_of_presentation",
      label: "Clarity of Presentation",
      score: 0,
    },
    { id: "depth_of_research", label: "Depth of Research", score: 0 },
  ]);
  const [data, setData] = useState({
    paperTitleName: "",
    paperAbstractDetail: "",
    status: "1",
    paper: "",
    paperDate: "",
    plagiarismDate: "",
    plagiarismStatus: "",
    plagiarismComment: "",
    plagiarismDocument: "",
    plagiarismExpired: "",
    revisionStatus: "",
    plagiarismAcceptedDate: "",
    isRepublish: "",
    republishDate: "",
    reviewDate: "",
    reviewComment: "",
    acceptanceDate: "",
    acceptanceStatus: "",
    registrationDate: "",
    acceptanceLetter: "",
    is_remodify: "",
    reviewing_file: "",
    is_registered_status: "0",
  });

  const getAbstractDetails = async () => {
    const response = await dispatch(fetchSubmittedAbstractsDetails(paperId));
    const {
      abstract_path,
      abstract_reviews,
      paperTitle,
      paper_abstract,
      revision_details,
      submittedPapersStatus,
      is_event_registered,
      revision_reasons,
    } = response?.data || {};
    setPaperData(response?.data || {});
    handelData(
      abstract_path,
      abstract_reviews,
      paperTitle,
      paper_abstract,
      revision_details,
      submittedPapersStatus,
      is_event_registered,
      revision_reasons
    );
  };

  const handelData = (
    file,
    abstractReviews,
    paperTitle,
    paper_abstract,
    revision_details,
    paperStatusData,
    is_event_registered = "",
    revision_reasons
  ) => {
    const {
      paper_submitted_date,
      paper_submitted_status,
      plagiarism_comment,
      plagiarism_date,
      plagiarism_status,
      plagiarism_document,
      plagiarism_expired,
      plagiarism_accepted_date,
      revision_status,
      is_republish_by_admin,
      republish_date,
      review_date,
      review_comment,
      acceptance_date,
      acceptance_status,
      registration_date,
      registration_acceptance_letter,
      is_remodify,
      reviewing_file,
      is_registered_status,
    } = paperStatusData || {};
    setData({
      paper: file,
      reviewData: abstractReviews,
      paperTitleName: paperTitle,
      paperAbstractDetail: paper_abstract,
      status: +paper_submitted_status || 1,
      paperDate: paper_submitted_date,
      revisionDetails: revision_details,
      isRepublish: is_republish_by_admin,
      republishDate: republish_date,
      plagiarismDate: plagiarism_date,
      plagiarismStatus: plagiarism_status,
      plagiarismComment: plagiarism_comment,
      plagiarismDocument: plagiarism_document,
      plagiarismExpired: plagiarism_expired,
      plagiarismAcceptedDate: plagiarism_accepted_date,
      revisionStatus: revision_status,
      reviewDate: review_date,
      reviewComment: review_comment,
      acceptanceDate: acceptance_date,
      acceptanceStatus: acceptance_status,
      registrationDate: registration_date,
      acceptanceLetter: registration_acceptance_letter,
      is_remodify: is_remodify,
      reviewing_file: reviewing_file,
      is_event_registered: is_event_registered,
      is_registered_status: is_registered_status,
      revisionReasons: revision_reasons,
    });
  };

  useEffect(() => {
    if (paperType === "paper") {
      //   getPaperDetails();
    } else if (paperType === "abstract") {
      getAbstractDetails();
    } else {
      // nothing
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paperData?.abstract_reviews) {
      const updatedScores = scores?.map((s) => {
        if (Object.keys(paperData?.abstract_reviews)?.includes(s.id)) {
          return {
            ...s,
            score: paperData?.abstract_reviews[s?.id],
          };
        }
        return s;
      });

      setScores(updatedScores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperData?.abstract_reviews]);

  const scrollToAbstract = (refData) => {
    if (refData?.current) {
      refData?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <>
      <Card className="d-flex align-items-center cps-12 cpt-12 cpb-12 unset-br mb-4">
        <span
          className="d-flex ps-2"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={icons.leftArrow} alt="back" className="h-21 me-3 pointer" />
          Submitted Abstract Track
        </span>
      </Card>

      <Card className="new-view-paper-container cpt-40 cpb-40 cps-40 cpe-40">
        {/* Abstract */}
        <UserAbstractState
          ref={abstractRevisionFormRef}
          data={data}
          paperData={paperData}
          handleSuccess={() => {
            getAbstractDetails();
          }}
          isAddRevisionAbstract={isAddRevisionAbstract}
          setIsAddRevisionAbstract={setIsAddRevisionAbstract}
          revisionFormRef={revisionFormRef}
        />

        {/* Plagiarism */}
        <UserPlagiarismState
          data={data}
          setIsAddRevisionAbstract={setIsAddRevisionAbstract}
          paperData={paperData}
          scrollToAbstract={scrollToAbstract}
          revisionFormRef={revisionFormRef}

        />

        {/* Review */}
        <UserReviewState
          data={data}
          paperData={paperData}
          handleSuccess={() => {
            getAbstractDetails();
          }}
          setIsAddRevisionAbstract={setIsAddRevisionAbstract}
        />

        {/* Acceptance */}
        <UserAcceptanceState data={data} paperData={paperData} />

        {/* Registration */}
        <UserRegistrationState
          data={data}
          paperData={paperData}
          handleSuccess={() => {
            getAbstractDetails();
          }}
        />

        {/* Attendance */}
        <UserAttendanceState
          data={data}
          paperData={paperData}
          handleSuccess={() => {
            getAbstractDetails();
          }}
        />
      </Card>
    </>
  );
};

export default NewViewPaper;
