import Card from "components/Layout/Card";
import ExportButton from "components/Layout/ExportButton";
import Table from "components/Layout/Table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CampaignDetailsPopup from "./CampaignDetailsPopup";
import { exportCampaignLogs, getCampaignData } from "store/slices";
import {
  getDataFromLocalStorage,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import ReplayMessageBlock from "pages/Admin/Reports/ReplayMessageBlock";

const numberOptions = Array.from({ length: 100 }, (_, i) => ({
  name: i + 1,
  value: i + 1,
}));

const CampaignManagement = () => {
  const { membershipList } = useSelector((state) => ({
    membershipList: state.global.membershipList,
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(false);
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 10,
  });
  const [tableList, setTableList] = useState([]);
  const userType = getDataFromLocalStorage("user_type");

  const [searchPayload, setSearchPayload] = useState({
    name: "",
    membership_id: "",
    user_type: "",
    compaign_name: "",
    user_event: "",
    before_completion: "",
    after_completion: "",
    data_filter: "",
    click: "",
    open: "",
  });

  const fetchData = async (obj) => {
    let formData = "";
    let modifiedObj = { ...obj };
    if (obj?.data_filter !== "custom") {
      delete modifiedObj?.start_date;
      delete modifiedObj.end_date;
      formData = objectToFormData(modifiedObj);
    } else {
      formData = objectToFormData(obj);
    }

    const response = await dispatch(getCampaignData(formData));
    let resList = [];
    let totalCount = 0;
    if (response?.data) {
      resList = response?.data?.users || [];
      totalCount = response?.data?.result_count || 0;
    }
    setTableList(resList);
    setFilterData({ ...obj, total: totalCount });
    setIsLoading(false);
  };

  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    fetchData(newData);
  };

  const handelPagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    fetchData(newData);
  };

  useEffect(() => {
    if (["0"].includes(userType)) {
      fetchData({ ...filterData, ...searchPayload });
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      isSearch: false,

      title: "S.No",
    },
    {
      isSearch: true,
      searchInputName: "name",
      title: "Name",
    },
    {
      isSearch: true,
      searchInputName: "member_id",
      title: <div className="text-nowrap">{"Member ID"}</div>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      dropdownOptions: {
        options: membershipList,
        key: "name",
        value: "name",
      },
      searchInputName: "user_type",
      title: <div className="text-nowrap">{"Member Type"}</div>,
    },
    {
      isSearch: true,
      searchInputName: "compaign_name",
      title: <div className="text-nowrap">Campaign Name</div>,
    },
    {
      isSearch: false,
      isSearchDropdown: true,
      dropdownOptions: {
        options: [],
        key: "name",
        value: "name",
      },
      title: <div className="text-nowrap">Channel</div>,
    },
    {
      isSearch: true,
      isDatePicker: true,
      searchInputName: "data_filter",
      title: "Date",
      isProfileManagement: true,
    },
    {
      isSearch: true,
      searchInputName: "click",
      isSearchDropdown: true,
      dropdownOptions: {
        options: [
          { value: "yes", name: "Yes" },
          { value: "no", name: "No" },
        ],
        key: "value",
        value: "name",
      },
      title: <div className="">Clicks</div>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      searchInputName: "open",
      dropdownOptions: {
        options: [
          { value: "yes", name: "Yes" },
          { value: "no", name: "No" },
        ],
        key: "value",
        value: "name",
      },
      title: <div className="">Login</div>,
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      searchInputName: "before_completion",
      dropdownOptions: {
        options: numberOptions,
        key: "name",
        value: "name",
      },
      title: (
        <>
          <div>{"Before"}</div>
          <div className="text-nowrap">{"Change (%)"}</div>
        </>
      ),
    },
    {
      isSearch: true,
      isSearchDropdown: true,
      searchInputName: "after_completion",

      dropdownOptions: {
        options: numberOptions,
        key: "name",
        value: "name",
      },
      title: (
        <>
          <div>{"After"}</div>
          <div className="text-nowrap">{"Change (%)"}</div>
        </>
      ),
    },
    {
      isSearch: false,
      title: "Remarks",
      searchLable: "View/Edit",
    },
  ];
  const rowData = [];
  tableList?.forEach((elem, index) => {
    const {
      name,
      member_id,
      member_type,
      compaign_name,
      compaign_date,
      // user_event,
      before_change,
      after_change,
      remark,
      click,
      open,
    } = elem;
    let obj = [
      {
        value: <span>{filterData?.offset + index + 1}</span>,
      },
      {
        value: (
          <span
            className="color-new-car pointer text-nowrap"
            onClick={() => {
              setUserData(elem);
            }}
          >
            {name}
          </span>
        ),
      },
      {
        value: <span className="text-nowrap">{member_id}</span>,
      },
      {
        value: <span className="text-nowrap">{member_type}</span>,
      },
      {
        value: (
          <span className="text-nowrap">
            {compaign_name ? compaign_name : "-"}
          </span>
        ),
      },
      {
        value: <span className="text-nowrap">{"-"}</span>,
      },
      {
        value: (
          <span className="text-nowrap">
            {moment(compaign_date).format("DD.MM.YYYY")}
          </span>
        ),
      },
      {
        value: (
          <span className="text-nowrap">
            {/* {user_event === "Clicked" ? "Yes" : "No"} */}
            {click ? titleCaseString(click) : "No"}
          </span>
        ),
      },
      {
        value: (
          <span className="text-nowrap">
            {/* {user_event === "Opened" ? "Yes" : "No"} */}
            {open ? titleCaseString(open) : "No"}
          </span>
        ),
      },
      {
        value: (
          <span className="text-nowrap">
            {before_change ? `${before_change}%` : "-"}
          </span>
        ),
      },
      {
        value: (
          <span className="text-nowrap">
            {after_change ? `${after_change}%` : "-"}
          </span>
        ),
      },
      {
        value: (
          <span className="action-icon-buttons">
            {remark && (
              <ReplayMessageBlock
                icon={<i className="bi bi-eye" />}
                comment={remark}
                viewType="view"
                elem={elem}
                handelSuccess={() => {
                  fetchData({ ...filterData, ...searchPayload });
                }}
                isCampaign
              />
            )}

            <ReplayMessageBlock
              showMessageIndicator
              icon={<i className="bi bi-chat-right-text position-relative" />}
              viewType="message"
              elem={elem}
              handelSuccess={() => {
                fetchData({ ...filterData, ...searchPayload });
              }}
              isCampaign
            />
          </span>
        ),
      },
    ];
    rowData.push({ data: obj });
  });

  return (
    <>
      {userData && (
        <CampaignDetailsPopup
          onHide={() => {
            setUserData(false);
          }}
          userData={userData}
        />
      )}
      <Card className="cps-20 cpe-20 cpb-20">
        <div className="d-flex justify-content-end cpt-20 cmb-20">
          <ExportButton
            buttonText={"All"}
            exportAPI={exportCampaignLogs}
            payload={objectToFormData({
              ...filterData,
              ...searchPayload,
            })}
          />
        </div>
        <div className="w-100 overflow-auto">
          <Table
            isLoading={isLoading}
            header={header}
            rowData={rowData}
            isOtherPagination
            filterData={filterData}
            searchPayload={searchPayload}
            searchInputChange={handelChangeSearch}
            changeOffset={handelPagination}
          />
        </div>
      </Card>
    </>
  );
};

export default CampaignManagement;
