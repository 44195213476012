import React, { useEffect, useState } from "react";
import Select from "react-select";
import Label from "components/form/Label";
import { includes } from "lodash";
import CheckBox from "components/form/CheckBox";
import Profile from "components/Layout/Profile";
import Button from "components/form/Button";
import { useDispatch } from "react-redux";
import {
  handleAllocateReviewers,
  throwError,
  throwSuccess,
} from "store/slices";
import "./ReviewerDropdown.scss";

const ReviewerDropdown = ({
  label,
  isRequired,
  labelClass,
  options,
  placeholder,
  optionKey,
  optionValue,
  value,
  isLoading,
  disabled,
  name,
  // onChange,
  id,
  handleSuccess,
  paperData,
  isPreviousReviewer,
}) => {
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [allocatedText, setAllocatedText] = useState(placeholder);
  const [btnLoader, setBtnLoader] = useState(false);
  const optId = optionKey || "id";
  const optVal = optionValue || "label";
  const {
    event_id,
    id: abstractID,
    allocated_reviewer,
    accepted_revision_paper_id,
  } = paperData || {};

  let fillValue = null;
  if (value) {
    const idArray = value?.split(",");
    fillValue = options?.filter((o) => includes(idArray, o[optId] + ""));
  }

  const handleCheckboxChange = (data) => {
    const isSelected = selectedOptions?.some((o) => o[optId] === data[optId]);
    const isAlreadyPresentInExistingList = allocated_reviewer?.some(
      (existingUser) => existingUser?.user_id === data[optId]
    );

    if (!isPreviousReviewer && isAlreadyPresentInExistingList) {
      dispatch(throwError({ message: "This user has already been selected." }));
      return;
    }
    const updatedOptions = isSelected
      ? selectedOptions?.filter((o) => o[optId] !== data[optId])
      : [...selectedOptions, data];

    setSelectedOptions(updatedOptions);
  };

  const handleAllocate = async () => {
    setBtnLoader(true);
    const totalReviewers = selectedOptions?.length;
    setAllocatedText(
      totalReviewers
        ? `${totalReviewers} Reviewer${totalReviewers > 1 ? "s" : ""} Allocated`
        : placeholder
    );
    const payload = {
      abstract_paper_id: abstractID,
      event_id: event_id,
      user_id: selectedOptions,
      revision_id: accepted_revision_paper_id,
      reviewer_revision: 1,
      allocation_type: 1,
    };

    const formData = new FormData();
    [
      "abstract_paper_id",
      "event_id",
      "revision_id",
      "reviewer_revision",
      "allocation_type",
    ].forEach((field) => {
      formData.append(field, payload[field]);
    });

    payload?.user_id?.forEach((user) => {
      formData.append(`user_id[]`, user?.id);
    });

    const response = await dispatch(handleAllocateReviewers(formData));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      handleSuccess();
      setSelectedOptions([]);
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setBtnLoader(false);
  };

  useEffect(() => {
    if (!isPreviousReviewer && paperData?.allocated_reviewer) {
      const totalCount = paperData?.allocated_reviewer?.length;
      setAllocatedText(
        paperData?.allocated_reviewer
          ? `${totalCount} Reviewer${totalCount > 1 ? "s" : ""} Allocated`
          : placeholder
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviousReviewer, paperData?.allocated_reviewer]);

  useEffect(() => {
    if (options && isPreviousReviewer?.length > 0) {
      const matchingReviewer = options?.filter((o) =>
        isPreviousReviewer?.some((p) => p?.user_id === o?.id)
      );

      if (matchingReviewer) {
        // If a match is found, add the reviewer's ID to the selectedOptions array if not already present
        setSelectedOptions((prevSelected) =>
          prevSelected?.includes(matchingReviewer?.id)
            ? prevSelected
            : [...prevSelected, ...matchingReviewer]
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPreviousReviewer, options]);

  const CustomOption = (props) => {
    const {
      id,
      name,
      email_id,
      member_id,
      phone_number,
      allocated_review,
      profile_image,
      area_of_interest
    } = props?.data || {};
    const isChecked = selectedOptions?.some(
      (o) => o[optId] === props?.data[optId]
    );
    const isDisabled = isPreviousReviewer?.some((o) => o?.user_id === id);
    const handleClick = () => {
      handleCheckboxChange(props?.data);
      props?.selectOption(props?.data);
    };

    return (
      <div
        className={`gap-3 custom-option ${isDisabled ? "disabled" : ""}`}
        onClick={handleClick}
        style={isDisabled ? { pointerEvents: "none", opacity: 0.5 } : {}}
      >
        <div className="me-2 ">
          <CheckBox
            type="PRIMARY-ACTIVE"
            onClick={() => {}}
            isChecked={isChecked}
            readOnly
          />
        </div>
        <div style={{ width: "48px", height: "48px" }}>
          <Profile url={profile_image} size="s-48" isRounded isS3UserURL />
        </div>
        <div className=" flex-grow-1 pointer row">
          <div className={`${area_of_interest && "cmb-12"} col-lg-4`}>
            <div className="text-14-400-18 color-black-olive cmb-12">
              {name}
            </div>
            <div className="text-12-400-15 color-black-olive">{member_id}</div>
            
          </div>
          <div className={`${area_of_interest && "cmb-12"} col-lg-5`}>
            <div className="text-14-300-17 color-black-olive cmb-12 w-fit-content d-flex align-items-center gap-2">
              <i className="bi bi-envelope" />
              {email_id}
            </div>
            <div className="text-14-300-17 color-black-olive w-fit-content d-flex align-items-center gap-2">
              <i className="bi bi-telephone" /> {phone_number}
            </div>
          </div>
          <div className={`${area_of_interest && "cmb-12"} col-lg-3`}>
            <div className="text-14-400-18 color-black-olive cmb-12">
              {"Allocated Review"}
            </div>
            <div className="text-14-400-18 color-black-olive">
              {allocated_review}
            </div>
          </div>
          <div className="col-lg-12">
          {area_of_interest&& <div className="text-12-400-15 color-black-olive">{area_of_interest?.split(",")?.join(", ")}</div>}

          </div>
        </div>
      </div>
    );
  };


  
  return (
    <>
      <div className="reviewer-dropdown-container">
        <div className="w-100">
          {label && (
            <Label label={label} required={isRequired} className={labelClass} />
          )}
          <Select
            isMulti
            placeholder={allocatedText}
            options={options}
            name={name}
            getOptionLabel={(option) => option[optVal]}
            getOptionValue={(option) => option[optId]}
            isDisabled={disabled}
            isLoading={isLoading}
            value={fillValue}
            components={{ Option: CustomOption }}
            className="basic-multi-select"
            classNamePrefix="select"
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
          />
        </div>
      </div>
      <div className="d-flex mt-4 allocate-button">
        <Button
          text="Allocate"
          btnStyle="primary-dark"
          className="h-35 cps-20 cpe-20"
          onClick={handleAllocate}
          disabled={selectedOptions?.length === 0}
          btnLoading={btnLoader}
        />
      </div>
    </>
  );
};

export default ReviewerDropdown;
